<template>
  <component
    :is="is"
    v-bind="$attrs"
    :to="to"
    :class="buttonClass"
    :disabled="isDisabled"
  >
    <svg
      v-if="loading"
      class="animate-spin h-5 w-5 absolute"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>

    <span :class="[loading && 'invisible', { 'w-full': noStyles }]">
      <component :is="iconOnly" :class="iconClass" class="h-6 w-6"></component>
      <div
        :class="[
          reverse && 'flex-row-reverse',
          { 'text-center flex items-center gap-[10px]': !noStyles },
          { 'w-full': noStyles },
        ]"
      >
        <component :is="leadingIcon" class="h-6 w-6"></component>
        <div class="w-full">
          <slot></slot>
        </div>
        <component
          :is="trailingIcon"
          class="h-6 w-6"
          :class="iconClass"
        ></component>
      </div>
    </span>
  </component>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from "vue";
import { cva } from "class-variance-authority";

type Size = "default" | "md" | "xs" | "sm" | "mdS" | "lg" | "xl" | null | undefined;
type Intent = "primary" | "primary-outline" | "secondary";

const nuxtLink = resolveComponent("NuxtLink");

const props = defineProps({
  text: {
    type: String,
    required: false,
    default: "",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  reverse: {
    type: Boolean,
    required: false,
    default: false,
  },
  size: {
    type: String as () => Size,
    required: false,
    default: "default",
  },
  iconOnly: Object,
  iconClass: {
    type: String,
    required: false,
    default: "",
  },
  leadingIcon: Object,
  trailingIcon: Object,
  intent: {
    type: String as () => Intent,
    required: false,
    default: "primary",
    validator: (value: string) =>
      ["primary", "primary-outline", "secondary", "tertiary", "destructive", "text"].includes(
        value
      ),
  },
  as: {
    type: [String, Object],
    required: false,
    default: "button",
  },
  to: {
    type: String,
    required: false,
    default: "",
  },
  scope: {
    type: String,
    required: false,
    default: "app",
  },
  noStyles: {
    type: Boolean,
    default: false,
  },
});

const intent = {
  primary:
    "bg-primary text-white hover:bg-primary-hover active:bg-primary-pressed focus-visible:outline-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:bg-primary-disabled",
  "primary-outline":
    "bg-white text-primary ring-1 ring-primary hover:text-white hover:bg-primary hover:ring-primary-hover active:border-primary-pressed active:text-primary-pressed disabled:bg-base-grey disabled:ring-grey-2 disabled:text-grey-4",
  secondary:
    "bg-white text-base-black ring-2 ring-neutrals-100 hover:text-primary-hover hover:bg-white hover:ring-primary-hover active:border-primary-pressed active:text-primary-pressed disabled:bg-base-grey disabled:ring-grey-2 disabled:text-grey-4",
};

const buttonClass = computed(() => {
  if (props.noStyles) return;
  return cva(
    `font-primary font-bold inline-flex items-center justify-center cursor-pointer`,
    {
      variants: {
        intent: intent,
        size: {
          default: ["px-[10px] py-[10px] rounded-[58px]"],
          md: ["px-3 py-2 rounded-[58px]"],
          lg: ["px-10 py-3 rounded-[58px]"],
          // xs: ["px-2 py-1 rounded-m"],
          // sm: ["px-2 py-1"],
          // mdS: ["px-3 py-[6px]"],
          // lg: ["px-3 py-2"],
        },
        disabled: {
          true: "!cursor-not-allowed",
        },
        loading: {
          true: "!cursor-wait",
        },
      },
    }
  )({
    intent: props.intent,
    size: props.size,
    disabled: props.disabled,
    loading: props.loading,
  });
});

const isDisabled = computed(() => {
  return props.disabled || props.loading;
});

const is = computed(() => {
  switch (props.as) {
    case "button":
      return "button";
    case "a":
      return "a";
    case "nuxt-link":
      return nuxtLink;
    default:
      return props.as;
  }
});
</script>
